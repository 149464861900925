import { defineStore } from 'pinia';

import type {
  TelessaudeServicesRoles,
  ProfileIds,
  UserProfile,
  AppConfig,
  AppConfigItems,
} from '@/types';

import { getUserProfiles } from '@/utils/mocks';

import {
  flushAppConfig,
  getDefaultAppConfig,
  getSavedAppConfig,
  saveAppConfig,
} from '@/utils/AppConfigHelper/AppConfigHelper';

interface UserBasicData {
  name: string,
  id: number,
}

interface AppState {
  appConfig: AppConfig,
  user: UserBasicData | null,
  activeProfileId: ProfileIds | null,
  userServicesRoles: TelessaudeServicesRoles | null,
}

export const useAppStore = defineStore('app', {
  state: (): AppState => ({
    appConfig: getDefaultAppConfig(),
    activeProfileId: null,
    user: null,
    userServicesRoles: null,
  }),
  getters: {
    getUserName(state) {
      return state.user?.name || '';
    },
    getUserId(state) {
      return state.user?.id || NaN;
    },
    getUserServicesRoles(state): TelessaudeServicesRoles | null {
      return state.userServicesRoles;
    },
    getActiveUserProfile(state): UserProfile {
      const profiles = getUserProfiles();
      return profiles.find((p: UserProfile) => p.id === state.activeProfileId)
        || profiles[0];
    },
  },
  actions: {
    loadAppConfig() {
      // TODO: Verificar qual formato será usado para o timestamp, atualmente está preparado para
      // tratar a quantidade de segundos desde o inicio da era unix.
      const configFlushThreshold = import.meta.env.VITE_FLUSH_CONFIG_OLDER_THAN;
      let localConfig = getSavedAppConfig();
      const shouldFlushAppConfig = localConfig && localConfig.lastFlush <= configFlushThreshold;

      if (shouldFlushAppConfig) {
        flushAppConfig();
        localConfig = null;
      }

      // Merges configurations to handle newly added properties
      const mergedConfig = {
        ...this.appConfig,
        ...localConfig,
      };

      this.appConfig = mergedConfig;
      saveAppConfig(this.appConfig);
    },
    setAppConfig(configsToSet: AppConfigItems) {
      if (Object.values(configsToSet as unknown[]).includes(undefined))
        throw new Error('setAppConfig - configsToSet properties should not contain undefined as value');

      this.appConfig = { ...this.appConfig, ...configsToSet };
      saveAppConfig(this.appConfig);
    },
    setActiveProfile(newProfileId: ProfileIds) { this.activeProfileId = newProfileId; },
    setUserData(data: UserBasicData) { this.user = data; },
    setUserServicesRoles(servicesRoles: TelessaudeServicesRoles) {
      this.userServicesRoles = servicesRoles;
    },
  },
})
