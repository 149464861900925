<template>
  <v-app>
    <app-navbar />
    <router-view />
  </v-app>
</template>

<script setup lang="ts">
import { onBeforeMount, watch } from 'vue';

import type { AppConfig } from '@/types';

import { useTheme } from 'vuetify';
import { useAppStore } from '@/store/app';

import AppNavbar from '@/components/AppNavbar/AppNavbar.vue';
import { decodeJwt, getCookies } from '@/utils/Auth/Auth';

const appStore = useAppStore();
const theme = useTheme();

const applyAppConfigs = (configs: AppConfig) => {
  theme.global.name.value = configs.theme;
}

watch(() => appStore.$state.appConfig, (newAppConfigs) => applyAppConfigs(newAppConfigs));

onBeforeMount(() => {
  appStore.loadAppConfig();

  // NOTE: at this moment, it makes no sense to set an active profile
  // appStore.setActiveProfile('teleconsultor');
  const cookies = getCookies();

  if (!cookies || !cookies.servicetoken) {
    throw new Error('User has no servicetoken to be parsed.');
  }

  const tokenData = decodeJwt(cookies.servicetoken);

  appStore.setUserServicesRoles(tokenData.claims.roles);

  const userName = tokenData.claims.username;
  const userId = tokenData.claims.id;

  appStore.setUserData({
    name: userName,
    id: userId,
  });
});
</script>

<style lang="scss">
/**
 * HACK: remove estilos de autofill para todos os inputs da aplicação, de foma global.
 * NOTE: a cor do texto definida funciona para inputs com cor de fundo "background" e "surface",
 * caso inputs com outras cores de fundo sejam criados, essa solução provavelmente deve adaptada.
 */
label + input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: rgb(var(--v-theme-on-background)) !important;
  -webkit-text-size-adjust: inherit !important;
}
/* make keychain icon white */
label + input::-webkit-credentials-auto-fill-button {
  background-color: rgb(var(--v-theme-on-background)) !important;
}
/* Hide credentials-auto-fill-button in password inputs, only visible on other inputs */
input[type=password]::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
}
</style>
