import TelessaudeModsEnum from '@/utils/Enums/TelessaudeModsEnum'
import RemoteCareProfilesEnum from '@md/RemoteCare/utils/Enums/RemoteCareProfilesEnum';

export default [
  {
    path: '/consultas-remotas',
    component: () => import(/* webpackChunkName: "remote-care" */ '@md/RemoteCare/RemoteCareModule.vue'),
    meta: {
      permissions: {
        mods: [TelessaudeModsEnum.LEGACY],
        services: {
          remotecare: [
            RemoteCareProfilesEnum.ADM,
            RemoteCareProfilesEnum.MONITOR,
            RemoteCareProfilesEnum.TELENUTRI_CONSULTOR,
          ],
        }
      },
    },
    children: [
      {
        // NOTE: Provisory redirect while there is no other services available
        path: '',
        redirect: { name: 'remote-care.telenutri' },
      },
      {
        path: 'telenutri',
        name: 'remote-care.telenutri',
        component: () => import(/* webpackChunkName: "remote-care.telenutri" */ '@md/RemoteCare/views/Telenutri.vue'),
      },
      {
        path: 'atendimento/:remotecareservice/:ticket',
        name: 'remote-care.attendant-form',
        component: () => import(/* webpackChunkName: "remote-care.attendant-form" */ '@md/RemoteCare/views/AttendantForm.vue'),
      },
    ],
  },
];
