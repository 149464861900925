import TelessaudeModsEnum from '@/utils/Enums/TelessaudeModsEnum'
// import RemoteCareProfilesEnum from '@md/RemoteCare/utils/Enums/RemoteCareProfilesEnum';
import Service0800ProfilesEnum from '@/modules/Service0800/utils/Enums/Service0800ProfilesEnum';

const manageProfiles = {
  '0800': [
    Service0800ProfilesEnum.ADM,
    Service0800ProfilesEnum.COORDENADOR,
  ],
};

// TODO: verificar se é necessário permitir esses perfis
// const seeProfiles = {
//   '0800': [
//     ...manageProfiles['0800'],
//     Service0800ProfilesEnum.CONSULTOR,
//     Service0800ProfilesEnum.REGULADOR,
//   ],
//   remotecare: [
//     RemoteCareProfilesEnum.TELENUTRI_CONSULTOR,
//   ],
// };

export default [
  {
    path: 'bibliografias',
    component: () => import(/* webpackChunkName: "bibliography" */ '@/modules/Bibliography/BibliographyModule.vue'),
    meta: {
      permissions: {
        mods: [TelessaudeModsEnum.LEGACY],
        // services: seeProfiles,
        services: manageProfiles,
      },
    },
    children: [
      {
        path: '',
        name: 'bibliography.list',
        component: () => import(/* webpackChunkName: "bibliography.list" */ '@/modules/Bibliography/views/BibliographyManagement.vue'),
      },
      {
        path: 'novo',
        name: 'bibliography.add',
        // meta: {
        //   permissions: {
        //     mods: [TelessaudeModsEnum.LEGACY],
        //     services: manageProfiles,
        //   },
        // },
        component: () => import(/* webpackChunkName: "bibliography.add" */ '@/modules/Bibliography/views/BibliographyManagementForm.vue'),
      },
      {
        path: 'editar/:uuid',
        name: 'bibliography.edit',
        // meta: {
        //   permissions: {
        //     mods: [TelessaudeModsEnum.LEGACY],
        //     services: manageProfiles,
        //   },
        // },
        component: () => import(/* webpackChunkName: "bibliography.edit" */ '@/modules/Bibliography/views/BibliographyManagementForm.vue'),
      },
    ],
  },
];
