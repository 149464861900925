import type { AppConfig } from '@/types';

export const getDefaultAppConfig = (): AppConfig => {
  return {
    lastFlush: new Date().getTime(),
    theme: 'telessaudeDefaultTheme',
  };
}

export const getSavedAppConfig = (): AppConfig | null => {
  const localConfig = window.localStorage.getItem('tl-config');
  if (!localConfig) return null;
  return JSON.parse(localConfig);
}

export const saveAppConfig = (newConfig: AppConfig) => {
  const encoded = JSON.stringify(newConfig);
  window.localStorage.setItem('tl-config', encoded);
}

export const flushAppConfig = () => {
  window.localStorage.removeItem('tl-config');
}
