const { VITE_ENVIRONMENT } = import.meta.env;

export const IS_PRD = VITE_ENVIRONMENT === 'PRD';
export const IS_HLG = VITE_ENVIRONMENT === 'HLG';
export const IS_DEV = VITE_ENVIRONMENT === 'DSV';

export const getEnv = () => {
  if (IS_PRD) return 'PRD';
  if (IS_HLG) return 'HLG';
  if (IS_DEV) return 'DEV';
  throw new Error('FATAL ERROR: environment variable is not defined properly! Check the environment set at the .env file.');
};
