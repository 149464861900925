import TelessaudeModsEnum from '@/utils/Enums/TelessaudeModsEnum'
import HealthcareAssistanceProfilesEnum from '@md/HealthcareAssistance/utils/Enums/HealthcareAssistanceProfilesEnum';

export default [
  {
    path: '/consultorias',
    component: () => import(/* webpackChunkName: "healthcare-assistance-module" */ '@md/HealthcareAssistance/HealthcareAssistanceModule.vue'),
    meta: {
      permissions: {
        mods: [TelessaudeModsEnum.LEGACY],
        services: {
          '0800': [ HealthcareAssistanceProfilesEnum.REGULADOR, ],
        }
      },
    },
    children: [
      {
        path: 'regulacao-ativa',
        name: 'healthcare-assistance.dynamic-prescription',
        component: () => import(/* webpackChunkName: "healthcare-assistance.dynamic-prescription" */ '@md/HealthcareAssistance/views/DynamicPrescriptionManagement.vue'),
      },
      {
        path: 'regulacao/:service/:ticket_id',
        name: 'healthcare-assistance.dynamic-prescription-form',
        component: () => import(/* webpackChunkName: "healthcare-assistance.dynamic-prescription-form" */ '@md/HealthcareAssistance/views/DynamicPrescriptionForm.vue'),
      },
    ],
  },
];
