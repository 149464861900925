// Components
import App from './App.vue';

// Composables
import { createApp } from 'vue';
import { createPinia } from 'pinia';

// Plugins
import { registerPlugins } from '@/plugins';
import VueGtag from 'vue-gtag';

// Directives
import { vMaska } from 'maska';

// Sentry
import * as Sentry from "@sentry/vue";

const { VITE_ENVIRONMENT, VITE_ANALYTICS, VITE_SENTRY_DSN } = import.meta.env;
const IS_PROD = VITE_ENVIRONMENT === 'PRD';

// const app = createApp(App)
// TODO: pinia was not working correclty in the devtools plugin when initialized
// through the previous method. With this fix, it is working, try to find out the best approach
// for this problem.
const app = createApp(App).use(createPinia())

if (IS_PROD) {
  app.use(VueGtag, {config: { id: VITE_ANALYTICS }});

  const TRACE_PROPAGATION_TARGETS = {
    LegacyPlataform: /^https:\/\/plataformatelessaude\.ufrgs\.br\/api/,
    PlatformX: /^https:\/\/api\.plataformatelessaude\.ufrgs\.br/
  }

  Sentry.init({
    app,
    dsn: VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.browserProfilingIntegration(),
    ],
    // Tracing
    tracesSampleRate: 0.7, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: Object.values(TRACE_PROPAGATION_TARGETS),
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // Profiling
    profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
  });
}

// Global app error handler
// TODO: we can configure it to handle any app error not captured, and send it to somewhere
// app.config.errorHandler = (err, instance, info) => {};

registerPlugins(app);

app.directive('maska', vMaska);

app.mount('#app');
