<script lang="ts" setup>
// types
import { MenuItem } from '@/types';

// components
import AppNavbarList from './AppNavbarList.vue';

const props = defineProps<{ data: MenuItem }>();

// handle toggle icon
const getDropdownIcon = (isOpen: boolean) => isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down';

// handle item click
const handleItemAction = () => {
  if (props.data.type === 'action' && typeof props.data.value === 'function')
    props.data.value();
}
</script>

<template>
  <template v-if="!data.subItems?.length">
    <v-list-item
      v-if="data.type === 'route' && typeof data.value === 'string'"
      class="app-navbar__list-item"
      :to="{ name: data.value }"
      :title="data.label"
      tag="li">
      <template #prepend><v-icon :icon="data.icon" size="x-large" /></template>
    </v-list-item>
    <v-list-item
      v-else-if="data.type === 'external' && typeof data.value === 'string'"
      class="app-navbar__list-item"
      :href="data.value"
      target="_blank"
      :title="data.label"
      tag="li">
      <template #prepend><v-icon :icon="data.icon" size="x-large" /></template>
    </v-list-item>
    <v-list-item
      v-else
      class="app-navbar__list-item"
      @click="handleItemAction"
      :title="data.label"
      tag="li">
      <template #prepend><v-icon :icon="data.icon" size="x-large" /></template>
    </v-list-item>
  </template>
  <v-list-group v-else :value="data.label">
    <template v-slot:activator="{ props, isOpen }">
      <v-list-item
        class="app-navbar__list-item"
        v-bind="props"
        :title="data.label"
        tag="li">
        <template #prepend><v-icon :icon="data.icon" size="x-large" /></template>
        <template #append>
          <v-icon
            class="ml-4"
            :icon="getDropdownIcon(isOpen)"
            size="x-large" />
        </template>
      </v-list-item>
    </template>
    <app-navbar-list :navlist-items="data.subItems" class="py-0" />
  </v-list-group>
</template>

<style lang="scss" scoped>
.app-navbar__list-item {
  padding-inline-start: 1.75rem !important;
  padding-inline-end: 1.75rem !important;
  .v-list-item__prepend .v-icon { margin-inline-end: 1rem !important; }
  &.v-list-item--active {
    color: rgba(var(--v-theme-primary));
    &:hover { background-color: transparent; }
  }
  &:hover {
    color: rgba(var(--v-theme-primary));
    background-color:  rgba(var(--v-theme-primary), .2);
  }

}

:deep() {
  &.v-list-item, &.v-list-group__items .v-list-item {
    padding-inline-start: 1.75rem !important;
    padding-inline-end: 1.75rem !important;
  }
}
</style>
