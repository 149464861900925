import { useAppStore } from '@/store/app';

import {
  UserProfile,
  MenuItem,
  NavMenuItemsList,
} from '@/types';

// import avatar from '@/assets/avatar.png';

// const appStore = useAppStore();

const getAppStore = () => useAppStore();

export function logout() {}

// export function getUserData() {
//   return {
//     name: getAppStore().getUserName,
//     avatar,
//   };
// }

/**
 * TODO:
 * Essa exibição de perfis possivelmente será revista, a ideia é replicar o comportamento
 * do lobby possibilitando ao usuário acessar diferentes sistemas de acordo com o seu perfil.
 * Ao que parece, alterar o perfil dentro da propria aplicação não terá nenhum efeito.
 * Isso pode ser melhor discutido em breve
 */
export function getUserProfiles(): UserProfile[] {
  // DEV: get profiles from token and make a structure similar to this one
  return [
    { id: 'teleconsultor', label: 'Teleconsultor' },
    { id: 'monitor', label: 'Monitor' },
    { id: 'coordenador', label: 'Coordenador' },
    { id: 'solicitante', label: 'Solicitante' },
  ];
}

export function getActiveProfile(): UserProfile {
  return getAppStore().getActiveUserProfile;
}

export function getUserProfilesAvailable() {
  return getUserProfiles().filter((p: UserProfile) => p.id !== getActiveProfile().id);
}

export function getAppNavbarListItems(): NavMenuItemsList {
  return _userMenuItemsMock;
}

export function getAppNavbarProfileListItems(
  profiles: UserProfile[],
  activeProfile: UserProfile
): MenuItem {
  return {
    type: 'toggle',
    label: getActiveProfile().label,
    icon: 'mdi-account',
    value: null,
    subItems: profiles
      .filter((p: UserProfile) => p.id !== activeProfile.id)
      .map((p: UserProfile) => {
        return {
          type: 'action',
          label: p.label,
          icon: 'mdi-acount',
          value: () => {
            console.log(`Mudando perfil para "${p.label}"`);
            getAppStore().setActiveProfile(p.id);
          },
          subItems: [],
        };
     }),
  };
}

const _userMenuItemsMock: NavMenuItemsList = [
  {
    type: 'toggle',
    label: 'Cadastros 0800',
    value: null,
    icon: 'mdi-book-edit-outline',
    subItems: [
      {
        type: 'route',
        icon: '',
        label: 'Bibliografias',
        value: 'bibliografias.gerenciamento',
      },
      // {
      //   type: 'route',
      //   icon: '',
      //   label: 'Chamadas',
      //   value: 'gerenciamento.chamadas',
      // },
      // {
      //   type: 'route',
      //   icon: '',
      //   label: 'Convênios',
      //   value: 'gerenciamento.convenios',
      // },
      // {
      //   type: 'route',
      //   icon: '',
      //   label: 'Estabelecimentos de saúde',
      //   value: 'gerenciamento.estabelecimentos',
      // },
      // {
      //   type: 'route',
      //   icon: '',
      //   label: 'Hipóteses',
      //   value: 'gerenciamento.hipoteses',
      // },
      // {
      //   type: 'route',
      //   icon: '',
      //   label: 'Usuários',
      //   value: 'gerenciamento.usuarios',
      // },
    ],
  },
  // {
  //   type: 'toggle',
  //   label: 'Plataforma 2.0',
  //   value: '',
  //   icon: '',
  //   subItems: [
  //     {
  //       type: 'route',
  //       icon: '',
  //       label: 'Bibliografias',
  //       value: 'gerenciamento-bibliografias',
  //     },
  //     {
  //       type: 'toggle',
  //       label: 'TEEEESTe',
  //       value: () => {},
  //       icon: '',
  //       subItems: [
  //         {
  //           type: 'action',
  //           label: 'Teste sub sub nav',
  //           value: () => alert('oie'),
  //           icon: '',
  //         }
  //       ],
  //     },
  //   ],
  // },
  // {
  //   type: 'route',
  //   icon: '',
  //   label: 'Cadastros',
  //   value: '',
  // },
  // {
  //   type: 'route',
  //   icon: '',
  //   label: 'Feedback',
  //   value: '',
  // },
  {
    type: 'route',
    icon: 'mdi-message-question-outline',
    label: 'Assistência médica (Consultorias)',
    value: '',
    subItems: [
      {
        type: 'route',
        label: 'Regulação ativa',
        value: 'healthcare-assistance.dynamic-prescription',
        icon: '',
      }
    ],
  },
  {
    type: 'route',
    icon: 'mdi-account-injury-outline',
    label: 'Consultas remotas',
    value: '',
    subItems: [
      {
        type: 'route',
        label: 'TeleNutri',
        value: 'remote-care.telenutri',
        icon: '',
      }
    ],
  },
  {
    type: 'action',
    icon: 'mdi-logout',
    label: 'Sair',
    value: () => {
      if (confirm('Você será deslogado, ok?'))
        location.href = 'http://127.0.0.1:8000/accounts/logout/';
    },
  },
];
