<script lang="ts" setup>
import { ref } from 'vue';
import { MenuItem } from '@/types';
import AppNavbarListItem from './AppNavbarListItem.vue';

const props = defineProps<{
  navlistItems: Array<MenuItem>,
}>();

const open = ref([]);
</script>

<template>
  <v-list v-model:opened="open" tag="ul">
    <app-navbar-list-item v-for="item, i in props.navlistItems" :key="i" :data="item"/>
  </v-list>
</template>
