import { MenuItem, NavMenuItemsList, TelessaudeServicesRoles } from '@/types';

import { IS_PRD } from '@/utils/EnvHelper/EnvHelper';
import Service0800ProfilesEnum from '@/modules/Service0800/utils/Enums/Service0800ProfilesEnum';

const { VITE_AUTH_WEB_URL, VITE_PLATAFORMA_LEGACY_URL } = import.meta.env;

const LobbyNavListItem: MenuItem = {
  type: 'action',
  icon: 'mdi-account',
  label: 'Trocar perfil',
  value: () => {
    if (confirm('Você será direcionado à seleção de perfil, ok?'))
      location.href = `${VITE_AUTH_WEB_URL}/`;
  },
};

const LogoutNavListItem: MenuItem = {
  type: 'action',
  icon: 'mdi-logout',
  label: 'Sair',
  value: () => {
    if (confirm('Você será deslogado, ok?'))
      location.href = `${VITE_PLATAFORMA_LEGACY_URL}/accounts/logout/`;
  },
};

const AdminNavListItems: MenuItem = {
  type: 'route',
  icon: 'mdi-cogs',
  label: 'Administrador',
  value: '',
  subItems: [
    {
      // TODO: ver se essa rota vai estar disponível apenas para admin ou também para outros usuários
      type: 'route',
      label: 'Bibliografias',
      value: 'bibliography.list',
      icon: '',
    },
  ],
};

const RemoteCareNavListItems: MenuItem = {
  type: 'route',
  icon: 'mdi-account-injury-outline',
  label: 'Consultas remotas',
  value: '',
  subItems: [
    {
      type: 'route',
      label: 'TeleNutri',
      value: 'remote-care.telenutri',
      icon: '',
    }
  ],
};

const HealthcareAssistanceNavListItems: MenuItem = {
  type: 'route',
  icon: 'mdi-message-question-outline',
  label: 'Consultorias',
  value: '',
  subItems: [
    {
      type: 'route',
      label: 'Regulação ativa',
      value: 'healthcare-assistance.dynamic-prescription',
      icon: '',
    }
  ],
};

// Handle user available navigation menu items
const getRemoteCareNavItems = (userRoles: TelessaudeServicesRoles) => {
  if (userRoles.remotecare && userRoles.remotecare.length) return RemoteCareNavListItems;
  return false;
};

const getHealcareAssistanceNavItems = (userRoles: TelessaudeServicesRoles) => {
  const roles0800 = userRoles['0800'];
  if (roles0800.includes(Service0800ProfilesEnum.REGULADOR)) return HealthcareAssistanceNavListItems;
  return false;
};

const getAdminListItems = (userRoles: TelessaudeServicesRoles) => {
  // TODO: verificar como vai ser definido o acesso a essas páginas.
  // quais perfis de admin, quais serviços, etc.
  const roles0800 = userRoles['0800'];
  const hasNeededRoles = roles0800.includes(Service0800ProfilesEnum.ADM)
    || roles0800.includes(Service0800ProfilesEnum.COORDENADOR)

  // Available only in DEV/HLG environment for now
  if (hasNeededRoles && !IS_PRD) return AdminNavListItems;

  return false;
};

export const getNavMenuList = (userRoles: TelessaudeServicesRoles | null): NavMenuItemsList => {
  const arrListItems: NavMenuItemsList = [];

  if (!userRoles) return arrListItems;

  const adminNavItems = getAdminListItems(userRoles);
  const remotecareNavItems = getRemoteCareNavItems(userRoles);
  const healthcareAssistanceNavItems = getHealcareAssistanceNavItems(userRoles);

  arrListItems.push(LobbyNavListItem);

  adminNavItems && arrListItems.push(adminNavItems);
  remotecareNavItems && arrListItems.push(remotecareNavItems);
  healthcareAssistanceNavItems && arrListItems.push(healthcareAssistanceNavItems);

  arrListItems.push(LogoutNavListItem);

  return arrListItems;
};
