// Plugins
import { loadFonts } from './webfontloader';
import vuetify from './vuetify';
// import pinia from '../store';
import router from '../router';

// Types
import type { App } from 'vue';

export function registerPlugins (app: App) {
  loadFonts();
  app.use(vuetify).use(router);
    // TODO: pinia devtools plugin is not working with this
    // .use(pinia);
}
