import type { TelessaudeServicesRoles } from "@/types";

interface ParsedToken {
  exp: number,
  iss: string,
  mod: string,
  claims: {
    id: number,
    roles: TelessaudeServicesRoles,
    sessionid: string,
    token: string,
    tutor: boolean,
    username: string,
  },
}

export function getCookies(): Record<string, string> | null {
  if (!document.cookie) return null;

  const cookiesObj: Record<string, string> = {};

  document.cookie.split(';').map((cookie) => {
    const [cookieName, cookieValue] = cookie.trim().split('=');
    cookiesObj[cookieName] = cookieValue;
  });

  return cookiesObj;
}

export function decodeJwt(token: string): ParsedToken {
  const tokenData = token.split('.')[1];
  return JSON.parse(atob(tokenData));
}

export function isAuthenticated(): boolean {
  const cookiesHashMap = getCookies();
  const jwt = cookiesHashMap?.servicetoken || cookiesHashMap?.temporarytoken;

  if (!jwt) return false;

  const tokenData = decodeJwt(jwt);
  const jwtExpiration = tokenData.exp * 1000;
  return jwtExpiration > (new Date()).getTime();
}

export function getTokenMod(): string | null {
  const cookiesHashMap = getCookies();
  if (!cookiesHashMap?.servicetoken) return null;
  return decodeJwt(cookiesHashMap.servicetoken).mod;
}

export function getTokenRoles(): TelessaudeServicesRoles | null {
  const cookiesHashMap = getCookies();
  if (!cookiesHashMap?.servicetoken) return null;
  return decodeJwt(cookiesHashMap.servicetoken).claims.roles || null;
  // return {
  //   remotecare: ['consultaremota_monitor']
  // };
}

/**
 * Verifica se o usuário possui autorização de acesso com base nas informações recebidas.
 *
 * @param modsFilter Os módulos que estão autorizados a acessar
 * @param servicesRolesFilter Os serviços e seus perfis que estão autorizados a acessar
 * @param userMod O módulo ao qual o usuários pertence
 * @param userSevicesRoles Os serviços e os perfis de acesso do usuário
 */
export function hasAuthorization(
  modsFilter: string[],
  servicesRolesFilter: Record<string, string[]> | undefined,
  userMod: string,
  userSevicesRoles: Record<string, string[]>,
) {
  if (!modsFilter.length) return true; // There are no access restrictions
  if (!modsFilter.includes(userMod)) return false; // User does not have the required mod permission

  if (!servicesRolesFilter) return true;

  const allowedServicesList = Object.keys(servicesRolesFilter);

  const userServicesList = Object.keys(userSevicesRoles);

  const userHasAnAllowedService = allowedServicesList
    .some((service: string) => userServicesList.includes(service));

  if (!userHasAnAllowedService) return false;

  let hasAuthorization = false;

  Object.entries(servicesRolesFilter).forEach((entry) => {
    const [service, serviceAllowedProfiles] = entry;
    const userProfileList = userSevicesRoles[service];

    // if (!serviceAllowedProfiles.length && userProfileList) {
    //   hasAuthorization = true;
    //   return;
    // }

    const userHasAllowedProfile = serviceAllowedProfiles
      .some((profile) => userProfileList.includes(profile));

    if (userHasAllowedProfile) hasAuthorization = true;
  });

  return hasAuthorization;
}
