// Styles
import '@mdi/font/css/materialdesignicons.css';
import '@/style/global.scss';

// Composables
import { createVuetify, type ThemeDefinition } from 'vuetify';

const telessaudeDefaultTheme: ThemeDefinition  = {
  colors: {
    background: '#F6F6F6',
    primary: '#008C88',
    'primary-light': '#19B2AC',
    secondary: '#6F53E9',
    success: '#008C88',
    warning: '#C58A14',
    error: '#D94A00',
    info: '#00A5D9',
  },
  variables: {
    // Custom variables can be included here
    'low-emphasis-opacity': .4,
  },
};

const telessaudeDarkTheme: ThemeDefinition  = {
  colors: {
    background: '#161616',
    surface: '#373737',
    primary: '#008C88',
    'primary-light': '#19B2AC',
    secondary: '#6F53E9',
    success: '#008C88',
    warning: '#C58A14',
    error: '#D94A00',
    info: '#00A5D9',
  },
  variables: {
    // Custom variables can be included here
    'low-emphasis-opacity': .4,
  },
};

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  theme: {
    defaultTheme: 'telessaudeDefaultTheme',
    themes: {
      telessaudeDefaultTheme,
      telessaudeDarkTheme,
    },
  },
});
